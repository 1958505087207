export const productCode = {
  car: "AUTOMOVIL",
  carLeasing: "AUTOMOVIL_LEASING",
  heavyCar: "PESADOS",
  heavyCarLeasing: "PESADOS_LEASING",
};

export default {
  productCode,
};
