<template>
  <div class="text-center">
    <v-dialog v-model="value" width="600" persistent attach="#main-container">
      <v-card class="overflow-hidden bordered pa-6" height="320">
        <div class="card-content">
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-space-between align-center">
                <span class="h4 semiBold pl-4"
                  >Simulación n° {{ quotNumber }} iniciada</span
                >
                <v-btn
                  icon
                  @click="cancel"
                  :disabled="loading.new || loading.continue"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <v-icon>mdi-text-search</v-icon>
            </v-col>
            <v-col cols="11">
              <span class="p mt-5 mb-0">
                La materia asegurada seleccionada en el ramo
                <strong>{{ quotData?.categoria_descripcion ?? "" }}</strong
                >, ya se encuentra simulada por
                <strong>{{ quotData?.nombre_usuario ?? "" }}</strong>

                desde el
                <strong>{{
                  (quotData?.fecha_creacion ?? "") | formatDate
                }}</strong
                >. </span
              ><br />
            </v-col>

            <v-col cols="11" offset="1">
              <span class="p mt-3">
                ¿Desea retomar la simulación o crear una nueva?
              </span>
            </v-col>
          </v-row>
          <v-row class="mb-4">
            <v-col>
              <div class="d-flex justify-center flex-column align-center">
                <v-btn
                  color="primary"
                  class="text-none bg-white"
                  outlined
                  rounded
                  :loading="loading.continue"
                  @click="quotContinue()"
                  width="214"
                  height="41"
                  name="boton_retomar"
                  >Retomar</v-btn
                >
                <v-btn
                  color="primary"
                  @click="quotNew()"
                  :loading="loading.new"
                  width="214"
                  rounded
                  class="mt-4 text-none"
                  >Crear nueva</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    value: false,
    loading: {
      continue: false,
      new: false,
    },
    dataClient: null,
    quotNumber: "",
    quotData: null,
    // Variables privadas
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),
  methods: {
    show(opts = {}) {
      // Una vez configurado las variables, se puede abrir la modal
      this.value = true;
      this.dataClient = opts?.dataClient ?? {};
      this.quotData = opts?.quotData ?? {};
      this.quotNumber = opts?.quotData.numero_cotizacion ?? "";
      // Retornar el resultado en Promise para que el usuario pueda obtener el valor de seleccion
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    quotContinue() {
      this.loading.continue = true;
      this.resolvePromise(false);
    },
    quotNew() {
      this.loading.new = true;
      this.resolvePromise(true);
    },

    cancel() {
      this.value = false;
      this.rejectPromise("Simulación cancelada.");
    },

    stopLoad() {
      this.loading.continue = false;
      this.loading.new = false;
    },

    close() {
      this.value = false;
      this.resolvePromise = undefined;
      this.rejectPromise = undefined;
    },
  },
};
</script>

<style scoped>
.v-dialog {
  border-radius: 4px;
  margin: 24px;
  overflow: hidden;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
}
</style>
