<template>
  <base-modal
    v-model="value"
    v-if="value"
    @close="value = false"
    persistent
    :closeButton="false"
    small
  >
    <section class="pa-6">
      <h4>{{ title }}</h4>
      <p>{{ description }}</p>
      <v-row>
        <v-col cols="6" class="text-center">
          <v-btn color="primary" block text @click="_confirm">Si</v-btn>
        </v-col>
        <v-col cols="6" class="text-center">
          <v-btn color="error" text block @click="_cancel">No</v-btn>
        </v-col>
      </v-row>
    </section>
  </base-modal>
</template>

<script>
import BaseModal from "./BaseModal.vue";
export default {
  components: { BaseModal },
  data: () => ({
    value: false,
    title: "",
    description: "",

    // Variables privadas
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),
  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.description = opts.description;
      // Una vez configurado las variables, se puede abrir la modal
      this.value = true;
      // Retornar el resultado en Promise para que el usuario pueda obtener el valor de seleccion
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.value = false;
      this.resolvePromise(true);
    },

    _cancel() {
      this.value = false;
      this.resolvePromise(false);
      // O se puede lanzar un error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
};
</script>

<style></style>
