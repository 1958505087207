<template>
  <v-container>
    <v-slide-y-transition mode="out-in">
      <CarChileForm
        v-if="branch?.codigo == 1 && isChile"
        ref="formComponent"
        v-bind="componentData"
        @set-dealer="getBranches"
        @set-branch="getSellers"
        :loadingDealers="loadingDealers"
        :loadingBranches="loadingBranches"
        :loadingSellers="loadingSellers"
        :codes="codes"
      />
      <CarPeruForm
        v-else-if="branch?.codigo == 1 && isPeru"
        ref="formComponent"
        v-bind="componentData"
        @set-dealer="getBranches"
        @set-branch="getSellers"
        :loadingDealers="loadingDealers"
        :loadingBranches="loadingBranches"
        :loadingSellers="loadingSellers"
        :codes="codes"
      />
      <p v-else>
        Haga clic en el boton de <b>Tarifas</b> para continuar con la
        simulación.
      </p>
    </v-slide-y-transition>

    <v-layout justify-end align-end class="mt-3">
      <v-flex class="mr-3">
        <v-slide-x-transition>
          <v-alert
            max-height="60"
            :value="error.show"
            :color="error.type"
            dense
            :type="error.type"
            outlined
            dark
            dismissible
            @input="inputAlert"
          >
            {{ error.text }}
          </v-alert>
        </v-slide-x-transition>
      </v-flex>
      <v-flex class="text-right ml-3">
        <v-btn
          color="primary"
          width="159"
          height="40"
          @click="submit"
          :loading="loading"
          rounded
          class="text-none"
          name="boton_cotizar"
          >Tarifas <v-icon right>mdi-tag-arrow-right</v-icon></v-btn
        >
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CarChileForm from "@/components/User-Components/Forms/CarChileForm.vue";
import CarPeruForm from "@/components/User-Components/Forms/CarPeruForm.vue";
import CountryMixin from "@/Mixins/CountryMixin.js";
import { productCode } from "@/helpers/frontCodes.js";
export default {
  name: "NewQuotForms",
  mixins: [CountryMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    branch: {
      type: Object,
      default: () => null,
    },
    dataClient: {
      type: Object,
      default: () => {},
    },
    assistedSelling: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default: () => ({
        type: "error",
        show: false,
        text: "",
      }),
    },
  },
  data: () => ({
    loadingDealers: false,
    loadingBranches: false,
    loadingSellers: false,
  }),
  created() {
    if (this.assistedSelling) {
      this.getDealers();
    }
  },
  components: {
    CarChileForm,
    CarPeruForm,
  },
  computed: {
    channel() {
      return this.$store.getters.getChannelSelected?.id;
    },
    dealers() {
      return this.$store.getters.Dealers;
    },
    branches() {
      return this.$store.getters.Branches;
    },
    sellers() {
      return this.$store.getters.Sellers;
    },
    codes() {
      return productCode;
    },
    componentData() {
      return {
        branch: this.branch,
        dataCategory: this.parseDataCategory,
        dataClient: this.parseDataClient,
        assistedSelling: this.assistedSelling,
        dealers: this.dealers,
        branches: this.branches,
        sellers: this.sellers,
      };
    },
    parseDataCategory() {
      return {
        categoria_descripcion: this.branch.ramo_descripcion,
        categoria_codigo: this.branch.codigo,
        categoria: this.branch._id,
      };
    },
    parseDataClient() {
      const ClientType = this.dataClient?.tipo_persona ?? "";
      const ClientDataKey = ClientType === "JUR" ? "contacto" : "cliente";
      return {
        tipo_documento: this.dataClient?.cliente?.tipo_documento ?? "RUT",
        numero_documento: this.dataClient?.cliente?.numero_documento ?? "",
        cliente: `${this.dataClient?.[ClientDataKey]?.nombre ?? ""} ${
          this.dataClient?.[ClientDataKey]?.primer_apellido ?? ""
        } ${this.dataClient?.[ClientDataKey]?.segundo_apellido ?? ""}`,
        primer_apellido:
          this.dataClient?.[ClientDataKey]?.primer_apellido ?? "",
        segundo_apellido:
          this.dataClient?.[ClientDataKey]?.segundo_apellido ?? "",
        nombres: this.dataClient?.[ClientDataKey]?.nombre ?? "",
        fecha_nacimiento:
          this.dataClient?.[ClientDataKey]?.fecha_nacimiento ?? "",
        genero: this.dataClient?.[ClientDataKey]?.genero ?? "",
        tipo_persona: ClientType,
        comuna: this.dataClient?.cliente?.comuna ?? "",
        comuna_descripcion: this.dataClient?.cliente?.comuna_descripcion ?? "",
        region: this.dataClient?.cliente?.region ?? "",
        region_descripcion: this.dataClient?.cliente?.region_descripcion ?? "",
        provincia: this.dataClient?.cliente?.provincia ?? "",
        provincia_descripcion:
          this.dataClient?.cliente?.provincia_descripcion ?? "",
      };
    },
  },
  methods: {
    async submit() {
      const formComponent = this.$refs.formComponent;
      const form = formComponent?.$refs?.form;
      let formData = {};
      // Verificar si existe un formulario para el ramo
      if (form) {
        // Validar formulario
        if (form?.validate()) {
          await formComponent.submit().then((response) => {
            formData = response;
          });
          this.$emit("submit", formData);
        }
      } else {
        // De lo contrario establecer data por defecto
        formData = {
          ...this.parseDataCategory,
          ...this.parseDataClient,
        };
        this.$emit("submit", formData);
      }
    },
    inputAlert(e) {
      if (!e) {
        this.$emit("close-alert");
      }
    },
    getDealers() {
      this.loadingDealers = true;
      this.$store.dispatch("REQUEST_DEALER", this.channel).finally(() => {
        this.loadingDealers = false;
      });
    },
    getBranches(dealer) {
      this.loadingBranches = true;
      this.$store.dispatch("REQUEST_BRANCHES", dealer._id).finally(() => {
        this.loadingBranches = false;
      });
    },
    getSellers(branch) {
      this.loadingSellers = true;
      this.$store.dispatch("REQUEST_SELLERS", branch._id).finally(() => {
        this.loadingSellers = false;
      });
    },
  },
};
</script>
