<template>
  <v-layout>
    <div>
      <v-btn
        v-for="(item, i) in items"
        :key="i"
        :disabled="item.eliminado"
        @click="selectRamo(item)"
        name="detalle_cliente"
        rounded
        color="primary"
        class="text-none px-6 py-6 mx-2"
        style="width: 144px; height: 40px"
      >
        <v-icon left>
          {{ item.codigo == 1 ? "mdi-car-outline" : "mdi-source-branch" }}
        </v-icon>
        {{ item.ramo_descripcion }}
      </v-btn>
    </div>
  </v-layout>
</template>

<script>
export default {
  name: "RamoCardList",
  data: () => ({
    ramo: "",
  }),
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    selectRamo(item) {
      this.$emit("input", item);
    },
  },
};
</script>
